<template>
    <div class=''>
        <p>123</p>
        <input @blur="blurEvent" type="text" v-model="value"/>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             value: 0
        })
        function blurEvent() {
             state.value = state.value < 0 ?
                0 : state.value > 99 ?
                99 : state.value
        }
        return {
            ...toRefs(state),
            blurEvent
        }
    },
}
</script>
<style lang='scss' scoped>
</style>